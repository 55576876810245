import Vue from 'vue'
import Router from 'vue-router'
import { authGuard } from '@/auth/authGuard'

import Clients from '@/routes/Clients'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: '/clients'
        },
        {
            path: '/clients',
            name: 'Clients',
            component: Clients,
            beforeEnter: authGuard
        }
    ]
})

export default router
