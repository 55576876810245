<template>
    <v-app>
        <v-app-bar
            color="indigo"
            dark
            fixed
            app
            clipped-left
        >
           
            <v-toolbar-title>KosherOS Auditor Console</v-toolbar-title>
            <v-spacer></v-spacer>
             <template>
         <div class="text-center">
            <v-menu  
            offset-y>
            <template v-slot:activator="{ on }">
          <v-btn
            icon
            x-small
            v-on="on"
          >    
            <v-list-item-avatar>
                    <img :src="`https://ui-avatars.com/api/?name=${auditor.name}&length=1`" alt="">
           </v-list-item-avatar>      
          </v-btn>
        </template>
            <v-list>
                <v-list-item
                v-for="(item, index) in items"
                :key="index" >
                     <v-list-item-content>
                         <v-list-item-title>{{ auditor.name }}</v-list-item-title>
                         <v-list-item-subtitle>{{ $auth.user.email }}</v-list-item-subtitle>
                         <template>    
                                <div class="pa-2" v-if="!$auth.loading">
                                <v-btn color="primary" block v-if="!$auth.isAuthenticated" @click="login">Login</v-btn>
                                <v-btn color="primary" block v-if="$auth.isAuthenticated" @click="logout">Logout</v-btn>
                                </div>
                        </template>
                     </v-list-item-content>
                 </v-list-item>
             </v-list>
         </v-menu>
         </div>
        </template>        
        </v-app-bar>
        <v-content>
            <router-view :auditor="auditor" />
        </v-content>
        <v-footer app class="pa-3">
            <v-layout row nowrap justify-space-around>
                <span class="">&copy; 2020 SafeTelecom</span>
            </v-layout>
        </v-footer>
    </v-app>
</template>

<script>
export default {
    data () {
        return {
            drawer: null,
            items: [
                { icon: 'mdi-account-multiple', text: 'Clients', path: '/clients' },
            ]
        }
    },
    computed: {
        auditor () {
            if (!this.$auth.loading) {
                var auditor = this.$auth.claims["http://safetelecom/safetelecom"]
                return auditor
            }
            return { name: 'null', picture: 'unset', licenses: 0 }
        }
    },
    methods: {
        login () {
            this.$auth.loginWithRedirect();
        },
        logout () {
            this.$auth.logout({
                returnTo: window.location.origin
            });
        },
        
    }
}
</script>

<style>
body {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
}
.footer{ /* Apply this to v-bottom-nav if necessary. */
    margin-bottom: constant(safe-area-inset-bottom);
    margin-bottom: env(safe-area-inset-bottom);
}
</style>
