var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"dark":"","color":"secondary"}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.auditor.name)+" Clients")]),_c('v-spacer'),_c('v-text-field',{attrs:{"hide-details":"","prepend-icon":"mdi-magnify","clearable":"","label":"Search","single-line":""},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.refreshPage}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-card',{attrs:{"tile":""}},[_c('v-card',{attrs:{"tile":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","search":_vm.searchTerm,"loading":_vm.dataLoading,"headers":_vm.headers,"items":_vm.clients,"footer-props":{
						'items-per-page-options': [25, 50, 100, 500, { 'text': 'All', 'value': -1 }]
					},"sort-by":['lastUpdated'],"sort-desc":[true],"items-per-page":50,"item-key":"_id","must-sort":"","show-expand":"","single-expand":_vm.singleExpand,"expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
					var headers = ref.headers;
					var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(item.name)+"'s Apps")]),_c('v-card-text',_vm._l((item.appNames),function(app){return _c('div',{key:app,staticClass:"sm-12 md-6"},[_vm._v(" "+_vm._s(app)+" ")])}),0)],1)],1)]}},{key:"item.phoneNumber",fn:function(ref){
					var item = ref.item;
return [_vm._v(_vm._s(item.phoneNumber ? new _vm.PhoneNumber( item.phoneNumber.toString(), 'US' ).toJSON()['number']['national'] : 'unset'))]}},{key:"item.lastUpdated",fn:function(ref){
					var item = ref.item;
return [_vm._v(_vm._s(_vm.moment(item.lastUpdated).format('M/D/YYYY h:mm a')))]}},{key:"item.emailPackage",fn:function(ref){
					var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.emailPackage ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'))])]}},{key:"item.whatsApp",fn:function(ref){
					var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.whatsApp ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'))])]}},{key:"item.activityBlocklist",fn:function(ref){
					var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.activityBlocklist.includes("60c8c8ddcd8b34003a98b217") ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'))])]}},{key:"item.lastCheckIn",fn:function(ref){
					var item = ref.item;
return [_vm._v(_vm._s(_vm.moment(item.lastCheckIn).calendar(null, { sameElse: 'M-D-YYYY h:mm:ss a' })))]}},{key:"item.latestBuildDate",fn:function(ref){
					var item = ref.item;
return [_vm._v(_vm._s(item.latestBuildDate ? _vm.moment(item.latestBuildDate).format('LL') : ''))]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }